import React, { useState } from "react";
import axios from "axios";
import countriesData from "../../countries.json";
import NavbarBack from "../../components/back_office/NavbarBack";
import NavBarBackPage from "../../components/back_office/NavBarBackPage";

const CreatDevis = () => {
  const [messageSupp, setMessageSupp] = useState("");
  const [warning, setWarning] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [prestation, setPrestation] = useState([]);
  const [formDataState, setFormDataState] = useState({
    Civility: "M",
    orga: "",
    Adresse: "",
    country: "France",
    zip: "",
    city: "",
    lastname: "",
    firstName: "",
    email: "",
    phone: "",
    validity: "",
    executeTime: "",
    discountType: "Rate",
    DiscountValue: "",
    shipping: "",
    distribeType: "Shipping",
    advance: "true",
  });

  const resetFormDataState = () => {
    setFormDataState({
      civility: "M",
      orga: "",
      Adresse: "",
      country: "France",
      zip: "",
      city: "",
      lastname: "",
      firstName: "",
      email: "",
      phone: "",
      validity: "",
      executeTime: "",
      discountType: "Rate",
      DiscountValue: "",
      shipping: "",
      distribeType: "Shipping",
      advance: "true",
    });
  };

  const handleChange = (value, name) => {
    setFormDataState((prevState) => ({ ...prevState, [name]: value }));
  };

  function addInput() {
    const newInput = { presta: "", qtyInput: "", price: "", vat: "20" };
    setPrestation([...prestation, newInput]);
  }

  const CreateDevis = async () => {
    setIsLoading(true);

    const {
      Adresse,
      zip,
      city,
      lastname,
      firstName,
      email,
      phone,
      validity,
      executeTime,
      distribeType,
    } = formDataState;

    if (/[^a-zA-Z\s]/.test(lastname) || /[^a-zA-Z\s]/.test(firstName)) {
      setIsLoading(false);
      setMessageSupp("");
      setWarning(
        "Le nom et le prénom ne peuvent pas contenir de caractères spéciaux"
      );
      return;
    }

    if (
      !Adresse ||
      !zip ||
      !city ||
      !lastname ||
      !firstName ||
      !email ||
      !phone ||
      !validity ||
      !executeTime ||
      !distribeType ||
      prestation.length === 0
    ) {
      setIsLoading(false);
      setMessageSupp("");
      setWarning("Veuillez remplire tous les champs obligatoirs");
      return;
    }

    const formatData = {
      formDataState: formDataState,
      prestation: prestation,
    };

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/devis/create",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      // console.log(response);
      setMessageSupp(response.data.message);
      setWarning("");
      setPrestation([]);
      resetFormDataState();
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavBarBackPage />
        <div className="input-devis-up-container admin-content-page">
          <div className="input-devis-bloc">
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label htmlFor="orga">Organisation</label>
                <input
                  id="orga"
                  name="orga"
                  value={formDataState.orga}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="input-devis-container">
                <label htmlFor="Adresse">Adresse *</label>
                <input
                  name="Adresse"
                  id="Adresse"
                  value={formDataState.Adresse}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
            </div>
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label>Pays *</label>
                <select
                  name="country"
                  id="country"
                  value={formDataState.country}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                >
                  {countriesData.map((country) => (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-devis-container">
                <label htmlFor="zip">Code postal *</label>
                <input
                  name="zip"
                  id="zip"
                  value={formDataState.zip}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="input-devis-container">
                <label htmlFor="city">Ville *</label>
                <input
                  name="city"
                  id="city"
                  value={formDataState.city}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="input-devis-bloc">
            <div className="input-devis-container">
              <label>Civilité *</label>
              <select
                name="Civility"
                id="Civility"
                value={formDataState.Civility}
                onChange={(e) => {
                  handleChange(e.target.value, e.target.name);
                }}
              >
                <option value="M">M</option>
                <option value="Mme">Mme</option>
              </select>
            </div>
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label htmlFor="lastname">Nom *</label>
                <input
                  name="lastname"
                  id="lastname"
                  value={formDataState.lastname}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="input-devis-container">
                <label htmlFor="firstName">Prenom *</label>
                <input
                  id="firstName"
                  name="firstName"
                  value={formDataState.firstName}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
            </div>
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label htmlFor="email">Email *</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formDataState.email}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="input-devis-container">
                <label htmlFor="phone">Telephone *</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formDataState.phone}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
            </div>
            <div></div>
          </div>
          <div className="input-devis-container">
            <label htmlFor="validity">Durée de validité (en jours) *</label>
            <input
              id="validity"
              name="validity"
              value={formDataState.validity}
              onChange={(e) => {
                handleChange(e.target.value, e.target.name);
              }}
            />
          </div>
          <div className="input-devis-container">
            <label htmlFor="executeTime">Durée d'execution (en jours) *</label>
            <input
              id="executeTime"
              name="executeTime"
              value={formDataState.executeTime}
              onChange={(e) => {
                handleChange(e.target.value, e.target.name);
              }}
            />
          </div>
          <div className="input-devis-container">
            <label>Demander un acompte *</label>
            <select
              name="advance"
              id="advance"
              value={formDataState.advance}
              onChange={(e) => {
                handleChange(e.target.value, e.target.name);
              }}
            >
              <option value="true">Acompte de 30%</option>
              <option value="false">Aucun acompte</option>
            </select>
          </div>
        </div>
        <div className="presta-container">
          <h4>Prestations *</h4>
          {prestation.map((input, index) => (
            <div className="input-presta-container" key={index}>
              <div className="input-devis-container">
                <label htmlFor={`presta-select-${index}`}>Prestation *</label>
                <textarea
                  className={
                    prestation[index].presta.length > 65 ? "text-too-long" : ""
                  }
                  name="presta"
                  cols="35"
                  rows="3"
                  maxLength={65}
                  id={`presta-select-${index}`}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    if (inputValue.length <= 65) {
                      const newInputs = [...prestation];
                      newInputs[index].presta = inputValue;
                      setPrestation(newInputs);
                    } else {
                      const tronqueeValue = inputValue.substring(0, 65) + "...";
                      const newInputs = [...prestation];
                      newInputs[index].presta = tronqueeValue;
                      setPrestation(newInputs);
                    }
                  }}
                />
                <small>65 caractères maximum</small>
              </div>
              <div className="input-devis-container">
                <label htmlFor={`qty-input-${index}`}>Quantité *</label>
                <input
                  name="presta"
                  id={`qty-input-${index}`}
                  value={input.qtyInput}
                  onChange={(e) => {
                    const newInputs = [...prestation];
                    newInputs[index].qtyInput = e.target.value;
                    setPrestation(newInputs);
                  }}
                  min="0"
                  step="1"
                />
              </div>
              <div className="input-devis-container">
                <label htmlFor={`price-select-${index}`}>PU.HT *</label>
                <input
                  name="presta"
                  id={`price-select-${index}`}
                  onChange={(e) => {
                    const newInputs = [...prestation];
                    newInputs[index].price = e.target.value;
                    setPrestation(newInputs);
                  }}
                />
              </div>
              <div className="input-devis-container">
                <label htmlFor={`vat-select-${index}`}>TVA *</label>
                <select
                  name="presta"
                  id={`vat-select-${index}`}
                  defaultValue="20"
                  onChange={(e) => {
                    const newInputs = [...prestation];
                    newInputs[index].vat = e.target.value;
                    setPrestation(newInputs);
                  }}
                >
                  <option value="5.5">5.5 %</option>
                  <option value="10">10 %</option>
                  <option value="20">20 %</option>
                  <option value="0">Hors TVA</option>
                </select>
              </div>
              <div>
                <button
                  className="remove-presta-button"
                  onClick={() => {
                    const newInputs = [...prestation];
                    newInputs.splice(index, 1);
                    setPrestation(newInputs);
                  }}
                >
                  Retirer
                </button>
              </div>
            </div>
          ))}
          <div>
            <button className="black-button" onClick={addInput}>
              Ajouter une prestation
            </button>
          </div>
        </div>
        <div className="shipping_devis_container">
          <h4>Distribution *</h4>
          <div className="section-input-devis">
            <div className="input-devis-container">
              <label>Type de distribution</label>
              <select
                name="distribeType"
                value={formDataState.distribeType}
                onChange={(e) => {
                  handleChange(e.target.value, e.target.name);
                }}
              >
                <option value="Shipping">Livraison</option>
                <option value="PickUp">Recuperation a nos locaux</option>
              </select>
            </div>
            <div className="input-devis-container">
              <label htmlFor="shipping">Frais de livraison</label>
              <input
                id="shipping"
                name="shipping"
                value={formDataState.shipping}
                onChange={(e) => {
                  handleChange(e.target.value, e.target.name);
                }}
              />
            </div>
          </div>
        </div>
        <div className="discount_devis_container">
          <h4>Remise</h4>
          <div className="section-input-devis">
            <div className="input-devis-container">
              <label>Type de remise</label>
              <select
                name="discountType"
                value={formDataState.discountType}
                onChange={(e) => {
                  handleChange(e.target.value, e.target.name);
                }}
              >
                <option value="Rate">Pourcentage</option>
                <option value="Fixed">Soutraction</option>
              </select>
            </div>
            <div className="input-devis-container">
              <label htmlFor="DiscountValue">Valeur de la remise</label>
              <input
                id="DiscountValue"
                name="DiscountValue"
                value={formDataState.DiscountValue}
                onChange={(e) => {
                  handleChange(e.target.value, e.target.name);
                }}
              />
            </div>
          </div>
        </div>
        <div className="footer-devis-page">
          <div className="btn-devis-page">
            <button className="black-button" onClick={resetFormDataState}>
              annuler
            </button>
            <button
              className="black-button"
              onClick={CreateDevis}
              disabled={isLoading}
            >
              {isLoading ? "Chargement..." : "Créer"}
            </button>
          </div>
        </div>
        <div className="alertSuccess">{warning}</div>
        <div className="alertSuccess">{messageSupp}</div>
      </div>
    </div>
  );
};

export default CreatDevis;
