import axios from "axios";
import React, { useEffect, useState } from "react";
import qs from "qs";
import Popup from "./Popup";

const DevisCardBack = (props) => {
  let { id } = props;

  const [devis, setDevis] = useState([]);
  const [togglePopup, setTogglePopup] = useState(false);
  const [devisExpired, setDevisExpired] = useState({ expiredClass: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEx, setIsLoadingEx] = useState(false);
  const [isAvailableEx, setIsAvailableEx] = useState(false);
  const [messagePopup, setMessagePopup] = useState("");
  const [comeFrom, setComeFrom] = useState("");

  useEffect(() => {
    const getDevis = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/devis/visualisation/${id}`)
        .then((res) => {
          const devissObj = Object.values(res.data);
          setDevis(devissObj[0]);
        });
    };
    getDevis();
  }, [id]);

  const datePerser = (date) => {
    if (date) {
      const newDate = new Date(date);
      const day = newDate.getDate().toString().padStart(2, "0");
      const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
      const year = newDate.getFullYear();
      return `${day}/${month}/${year}`;
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (devis) {
      const dateDevis = new Date(devis.dateDevis);

      const validityDays = parseInt(devis.validity, 10);
      const endDate = new Date(dateDevis);
      endDate.setDate(dateDevis.getDate() + validityDays);

      const currentDate = new Date();

      if (currentDate > endDate) {
        setDevisExpired({ expiredClass: false });
      } else {
        setDevisExpired({ expiredClass: true });
      }
    }
  }, [devis]);

  const openPdf = () => {
    window.open(
      "https://api.artimarq.fr/public/devis/" + devis?.nameFile,
      "_blank"
    );
  };

  const deleteDevis = () => {
    const formatData = {
      idDevis: id,
      nameFile: devis?.nameFile,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/devis/delete",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createBill = () => {
    setIsLoading(true);

    const formatData = {
      idDevis: id,
      fisrtNameDevis: devis?.fisrtNameDevis,
      lastNameDevis: devis?.lastNameDevis,
      organisationDevis: devis?.organisationDevis,
      addressDevis: devis?.addressDevis,
      zipDevis: devis?.zipDevis,
      cityDevis: devis?.cityDevis,
      countryDevis: devis?.countryDevis,
      emailDevis: devis?.emailDevis,
      phoneDevis: devis?.phoneDevis,
      services: devis?.services,
      discount: devis?.discount,
      typeDiscount: devis?.typeDiscount,
      distribeType: devis?.distribeType,
      shipping: devis?.shipping,
      advance: devis?.AdvanceEstimate,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/bills/create",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        setIsLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const sendDevis = () => {
    setIsLoadingEx(true);

    const formatData = {
      idDevis: id,
      number_devis: devis?.idDevis,
      firstName: devis?.fisrtNameDevis,
      lastName: devis?.lastNameDevis,
      validity: devis?.validity,
      email: devis?.emailDevis,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/devis/send-devis",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        setIsLoadingEx(false);
        if (response.data === true) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingEx(false);
      });
  };

  const sendAvailable = () => {
    setIsAvailableEx(true);

    const formatData = {
      idDevis: id,
      number_devis: devis?.idDevis,
      firstName: devis?.fisrtNameDevis,
      lastName: devis?.lastNameDevis,
      validity: devis?.validity,
      email: devis?.emailDevis,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/devis/available-order",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        setIsAvailableEx(false);
        if (response.data === true) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
        setIsAvailableEx(false);
      });
  };

  // distribeType

  const btnSend = devis?.isShared === "1" ? "Envoyer à nouveau" : "Envoyer";
  const btnAvailabe =
    devis?.isAvailable === "1"
      ? "Renvoyer la disponibilité"
      : "CMDE disponible";

  const isDeducted = devis?.isDeducted === "1";
  const isNotDeducted = devis?.isDeducted === "0";
  const isFactured = devis?.isFactured === "1";
  const isNotFactured = devis?.isFactured === "0";
  const isAdvanceEstimate = devis?.AdvanceEstimate === "true";
  const shouldDisableButton = isLoading || isFactured;

  let btnBill = null;

  if (isAdvanceEstimate && isNotDeducted && isNotFactured) {
    btnBill = "Creer un décompte";
  } else if (
    (isAdvanceEstimate && isDeducted && isNotFactured) ||
    (!isAdvanceEstimate && isNotFactured)
  ) {
    btnBill = "Creer une facture";
  } else {
    btnBill = "Devis facturé";
  }

  return (
    <ul className="devis-card-back">
      <li>
        <h5>Demandeur</h5>
        <span>
          {devis?.civilityDevis} {devis?.fisrtNameDevis} {devis?.lastNameDevis}
        </span>
      </li>
      <li>
        <h5>Organisation</h5>
        <span>{devis?.organisationDevis}</span>
      </li>
      <li>
        <h5>Devis N</h5>
        <span>{devis?.idDevis}</span>
      </li>
      <li>
        <h5>Date</h5>
        <span>{datePerser(devis?.dateDevis)}</span>
      </li>
      <li>
        <h5>{isDeducted ? "Statut" : "Validité"}</h5>
        <span>{isDeducted ? "" : `${devis?.validity} jours`}</span>
        <span
          style={
            isDeducted
              ? { color: "green" }
              : devisExpired.expiredClass
              ? { color: "orange" }
              : { color: "red" }
          }
        >
          {isDeducted
            ? "Accepté"
            : devisExpired.expiredClass
            ? "Valide"
            : "Expiré"}
        </span>
      </li>
      <li className="last-item">
        <button className="card-btn" onClick={openPdf}>
          Consulter
        </button>
        <button
          className="card-btn"
          onClick={() => {
            setTogglePopup(true);
            setMessagePopup(
              "En confirmant l'envoie, le dévis sera immédiatement envoyé au client !"
            );
            setComeFrom("sendBill");
          }}
          disabled={isLoadingEx}
        >
          {isLoadingEx ? "..." : btnSend}
        </button>
        <button
          className="card-btn"
          onClick={() => {
            setTogglePopup(true);
            setMessagePopup("Souhaitez vous generer une nouvelle facture ?");
            setComeFrom("Createbill");
          }}
          disabled={shouldDisableButton}
        >
          {isLoading ? "..." : btnBill}
        </button>
        {devis?.distribeType === "PickUp" && (
          <button
            className="card-btn"
            onClick={() => {
              setTogglePopup(true);
              setMessagePopup(
                "Apres confirmation, un email sera immédiatement envoyé au client !"
              );
              setComeFrom("availableOrder");
            }}
            disabled={isAvailableEx}
          >
            {isAvailableEx ? "..." : btnAvailabe}
          </button>
        )}
        <button
          className="card-btn"
          onClick={() => {
            setTogglePopup(true);
            setMessagePopup(
              "La suppression est irreversible, êtes-vous certain de vouloir supprimer ce deivs ?"
            );
            setComeFrom("devisDeletion");
          }}
        >
          Supprimer
        </button>
      </li>
      <Popup
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        onSubmite={
          comeFrom === "sendBill"
            ? sendDevis
            : comeFrom === "devisDeletion"
            ? deleteDevis
            : comeFrom === "availableOrder"
            ? sendAvailable
            : comeFrom === "Createbill"
            ? createBill
            : null
        }
        messagePopup={messagePopup}
        comeFrom={comeFrom}
      />
    </ul>
  );
};

export default DevisCardBack;
