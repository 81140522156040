import React, { useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ParamContext } from "./context/ParamContexte";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ReactGA from "react-ga";

const Footer = () => {
  const {
    logo,
    facebook,
    instagram,
    tiktok,
    linkdin,
    nameCompany,
    statusCompany,
  } = useContext(ParamContext);

  const currentYear = new Date().getFullYear();
  const location = useLocation();

  const [toggleMessage, setToggleMessage] = useState("");
  const [isFalseRes, setIsFalseRes] = useState("");

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Veuillez saisir un email valide (ex. mon-addresse@gmail.com")
      .required("Veuillez saisir un email"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const submitSubscription = async (data) => {
    ReactGA.event({
      category: "Newsletter",
      action: "Inscription",
    });

    const formatData = {
      email: data.email,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/newsletter/subscription",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          setToggleMessage(response.data.message);
          setIsFalseRes(false);
          reset();
          setTimeout(() => {
            setToggleMessage("");
            setIsFalseRes(false);
          }, 5000);
        } else {
          setToggleMessage(response.data.message);
          setIsFalseRes(true);
        }
      })
      .catch((error) => {
        alert(error.data.message);
      });
  };

  return (
    !(
      location.pathname.startsWith("/back/") ||
      location.pathname === "/desinscription-newsletter" ||
      location.pathname === "/carte-de-visite"
    ) && (
      <footer>
        <div className="upFooter">
          <div className="img-footer-container">
            <img src={logo} alt="logo" />
          </div>
          <div className="network-container">
            <span>Suivez-nous</span>
            <div className="icon-network-container">
              <a href={facebook} target="_blank" rel="noreferrer">
                <svg
                  className="icon-rs-footer"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 640"
                >
                  <path d="M380.001 120.001h99.993V0h-99.993c-77.186 0-139.986 62.8-139.986 139.986v60h-80.009V320h79.985v320h120.013V320h99.994l19.996-120.013h-119.99v-60.001c0-10.843 9.154-19.996 19.996-19.996v.012z" />
                </svg>
              </a>
              <a href={instagram} target="_blank" rel="noreferrer">
                <svg
                  className="icon-rs-footer"
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  viewBox="0 0 640 640"
                >
                  <path d="M184.715 2.752h270.582C556.908 2.752 640 84.816 640 185.069v269.85c0 100.277-83.092 182.317-184.703 182.317H184.715C83.104 637.236 0 555.196 0 454.919v-269.85C0 84.816 83.103 2.752 184.715 2.752zm133.561 145.939c98.741 0 178.868 80.127 178.868 178.868 0 98.753-80.127 178.868-178.868 178.868-98.765 0-178.88-80.115-178.88-178.868 0-98.741 80.115-178.868 178.88-178.868zm0 60.414c65.387 0 118.454 53.056 118.454 118.454s-53.068 118.466-118.454 118.466c-65.41 0-118.466-53.067-118.466-118.466 0-65.398 53.056-118.454 118.466-118.454zM491.321 123.9c16.04 0 29.044 13.004 29.044 29.032 0 16.04-13.004 29.044-29.044 29.044-16.028 0-29.032-13.004-29.032-29.044 0-16.028 13.004-29.032 29.032-29.032zM206.825 54.58H433.21c85.005 0 154.526 69.178 154.526 153.712V435.81c0 84.532-69.52 153.699-154.526 153.699H206.825c-85.005 0-154.537-69.167-154.537-153.7V208.29c0-84.532 69.532-153.71 154.537-153.71z" />
                </svg>
              </a>
              <a href={tiktok} target="_blank" rel="noreferrer">
                <svg
                  className="icon-rs-footer"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 2859 3333"
                >
                  <path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z" />
                </svg>
              </a>
              <a href={linkdin} target="_blank" rel="noreferrer">
                <svg
                  className="icon-rs-footer"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 640"
                >
                  <path d="M228.582 205.715h126.462v64.832h1.83c17.611-31.595 60.675-64.832 124.892-64.832C615.303 205.715 640 288.818 640 396.926v220.219H508.116V421.93c0-46.536-.969-106.442-68.576-106.442-68.67 0-79.194 50.658-79.194 103.052v198.605H228.581v-411.43zM137.152 91.43c0 37.855-30.721 68.576-68.576 68.576-37.855 0-68.587-30.721-68.587-68.576 0-37.855 30.732-68.576 68.587-68.576 37.855 0 68.576 30.721 68.576 68.576zM-.011 205.715h137.163v411.43H-.011v-411.43z" />
                </svg>
              </a>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(submitSubscription)}
            className="newsletter-input-container"
          >
            <label htmlFor="newsletter-input-front"> Restez informé(e).</label>
            <div className="sunscribe-newsletter">
              <input
                type="text"
                placeholder="E-mail"
                id="newsletter-input-front"
                name="email"
                {...register("email")}
              />
              <button className="subscrib-news-button">Inscription</button>
            </div>
            <small className={isFalseRes ? "errorInputConfig" : "res-news"}>
              {errors.email ? "" : toggleMessage}
            </small>
            {errors.email && (
              <small className="errorInputConfig">
                {errors.email?.message}
              </small>
            )}
          </form>
        </div>
        <div className="downFooter">
          <ul>
            <li>
              <NavLink to="/mentions-legales">Mentions legales</NavLink>
            </li>
            <li>
              <NavLink to="/protection-des-données">
                Protection des données & cookies
              </NavLink>
            </li>
            <li>
              <NavLink to="CGV">CGV</NavLink>
            </li>
            <li>
              <NavLink to="/FAQ">FAQ</NavLink>
            </li>
            <li>
              <NavLink to="/contacts">Contacts</NavLink>
            </li>
          </ul>
          <span>
            Copyright © {nameCompany} {statusCompany} {currentYear} - tous
            droits réservés
          </span>
        </div>
      </footer>
    )
  );
};

export default Footer;
