import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import qs from "qs";
import countriesData from "../countries.json";
import { ParamContext } from "../components/context/ParamContexte";
import { NavLink } from "react-router-dom";
import ReactGA from 'react-ga';

const AskDevis = (props) => {
  let {
    fromCustom,
    handleBack,
    nameProduct,
    selectedColor,
    selectedGSM,
    selectedStyle,
    selectedTechnic,
    selectedDelays,
    selectedZones,
    selectedSizes,
    setSelectedColor,
    setSelectedGSM,
    setSelectedTechnic,
    setSelectedDelays,
    setSelectedStyle,
    setSelectedZones,
    setSelectedSizes,
    emptyConfig,
  } = props;

  const { nameCompany, imgOpenTags } = useContext(ParamContext);

  const baseUrl = "https://api.artimarq.fr/public/images/";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const options = [
    "Association",
    "Collectivité",
    "Entreprise",
    "Marque",
    "Particulier",
  ];

  const [selectedOption, setSelectedOption] = useState(null);
  const [toggleMessage, setToggleMessage] = useState("");
  const [files, setFiles] = useState([]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleChangeFiles = (event) => {
    const newFiles = event.target.files;
    const currentFiles = files;
    const allFiles = currentFiles ? [...currentFiles] : [];

    for (let i = 0; i < newFiles.length; i++) {
      allFiles.push(newFiles[i]);
    }
    setFiles(allFiles);
  };

  const handleRemoveImage = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const validationSchema = Yup.object().shape({
    radioOrga: Yup.string().required("Veuillez selectioner une option"),
    orgaCountry: Yup.string().test(
      "orgaCountry",
      "Veuillez selectioner une option",
      function (orgaCountry) {
        return orgaCountry !== "Choisissez un pays *";
      }
    ),
    orgaAddress: Yup.string().required("Veuillez saisir une adresse"),
    zipOrga: Yup.string().required("Veuillez saisir un code postal"),
    cityOrga: Yup.string().required("Veuillez saisir une ville de residence"),
    civilityDevis: Yup.string().required("Veuillez selectioner une option"),
    personFirstName: Yup.string().required("Veuillez saisir votre nom"),
    personLastName: Yup.string().required("Veuillez saisir votre prénom"),
    personEmail: Yup.string()
      .email("Veuillez saisir un email valide (ex. mon-addresse@gmail.com")
      .required("Veuillez saisir un email"),
    personPhone: Yup.string().required(
      "Veuillez saisir un nemero de telephone"
    ),
    explainProject: Yup.string().required(
      "Veuillez fournir plus d'explication"
    ),
    acceptTerms: Yup.bool()
      .transform((value, originalValue) =>
        originalValue === "on" ? true : value
      )
      .oneOf([true], "Accepter les conditions est obligatoire"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      acceptTerms: false,
      newsletter: true,
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    const formatData = {
      radioOrga: data.radioOrga,
      orgaName: data.orgaName,
      orgaCountry: data.orgaCountry,
      orgaAddress: data.orgaAddress,
      zipOrga: data.zipOrga,
      cityOrga: data.cityOrga,
      civilityDevis: data.civilityDevis,
      personFirstName: data.personFirstName,
      personLastName: data.personLastName,
      personEmail: data.personEmail,
      personPhone: data.personPhone,
      explainProject: data.explainProject,
      acceptTerms: data.acceptTerms,
      newsLetter: data.newsletter,
      nameProduct,
      selectedColor,
      selectedGSM,
      selectedStyle,
      selectedTechnic,
      selectedDelays,
      selectedZones,
      selectedSizes,
    };

    const filesData = new FormData();
    for (let i = 0; i < files.length; i++) {
      filesData.append("file[]", files[i]);
    }

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/front/sendOrder",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      // console.log(response);
      setToggleMessage(response.data.message);
      reset();
      setFiles([]);
      setSelectedOption(null);
      setSelectedColor(null);
      setSelectedGSM(null);
      setSelectedTechnic(null);
      setSelectedDelays(null);
      setSelectedStyle([]);
      setSelectedZones([]);
      setSelectedSizes([]);
      emptyConfig();
    } catch (error) {
      console.log(error.message);
    }

    try {
      // const response =
      await axios.post(
        "https://api.artimarq.fr/front/uploadImages",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleGaData = () => {
    ReactGA.event({
      category: "Devis",
      action: "Demander un devis",
    });
  };

  return (
    <form className="classic-page devis-page" onSubmit={handleSubmit(onSubmit)}>
      <Helmet>
        <title>{`${nameCompany} | Demandez un devis gratuit`}</title>
        <meta
          property="og:title"
          content="Demandez un devis gratuit"
        />
        <meta property="og:description" content="Partagez nous votre projet." />
        <meta property="og:type" content="service" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:url" content="https://artimarq.fr/demande-de-devis" />
        <meta property="og:site_name" content="ARTIMARQ site officiel" />
        <meta
          name="description"
          content="Marque, Entreprise, Associaction, Collectivité ? Partagez nous votre projet textile, et beneficiez du meilleur accompagnement possible."
        />
        <link rel="canonical" href={`https://artimarq.fr/demande-de-devis`} />
      </Helmet>
      {fromCustom && (
        <div className="back-button-container">
          <div className="black-button" onClick={() => handleBack()}>
            Retour
          </div>
        </div>
      )}
      <div className="organisation-infos">
        <div className="head-ask-devis">
          <span>Etes-vous ?</span>
        </div>
        <div className="body-ask-devis">
          {options.map((option) => (
            <label
              key={option}
              htmlFor={option}
              className={`orga-items ${
                selectedOption === option ? "orga-selected" : ""
              }`}
            >
              <input
                id={option}
                type="radio"
                name="radioOrga"
                value={option}
                {...register("radioOrga")}
                onClick={() => handleOptionClick(option)}
              />
              {option}
            </label>
          ))}
        </div>
      </div>
      {errors.radioOrga && (
        <small className="errorInputConfig">{errors.radioOrga?.message}</small>
      )}
      <div className="address-infos">
        <div className="head-ask-devis">
          <span>Votre adresse</span>
        </div>
        <div className="body-ask-devis">
          <div className="input-devis-container">
            <label htmlFor="orga-name">
              Nom de votre organisation (facultatif)
            </label>
            <input
              type="text"
              id="orga-name"
              name="orgaName"
              {...register("orgaName")}
            />
          </div>
          <div className="input-devis-container">
            <label htmlFor="orgaAddress">Adresse</label>
            <input
              type="text"
              id="orgaAddress"
              name="orgaAddress"
              {...register("orgaAddress")}
            />
            {errors.orgaAddress && (
              <small className="errorInputConfig">
                {errors.orgaAddress?.message}
              </small>
            )}
          </div>
          <div className="input-devis-container">
            <label htmlFor="zip-orga">Code postal</label>
            <input
              type="text"
              id="zip-orga"
              name="zipOrga"
              {...register("zipOrga")}
            />
            {errors.zipOrga && (
              <small className="errorInputConfig">
                {errors.zipOrga?.message}
              </small>
            )}
          </div>
          <div className="input-devis-container">
            <label htmlFor="city-orga">Ville</label>
            <input
              type="text"
              id="city-orga"
              name="cityOrga"
              {...register("cityOrga")}
            />
            {errors.cityOrga && (
              <small className="errorInputConfig">
                {errors.cityOrga?.message}
              </small>
            )}
          </div>
          <div className="input-devis-container">
            <label>Pays</label>
            <select
              name="orgaCountry"
              {...register("orgaCountry")}
              defaultValue="Choisissez un pays *"
            >
              <option disabled>Choisissez un pays *</option>
              {countriesData.map((country) => (
                <option key={country.code} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
            {errors.orgaCountry && (
              <small className="errorInputConfig">
                {errors.orgaCountry?.message}
              </small>
            )}
          </div>
        </div>
      </div>
      <div className="id-infos">
        <div className="head-ask-devis">
          <span>Vos informations</span>
        </div>
        <div className="civility-devis-container">
          <div className="civility-opt">
            <label
              className={errors.civilityDevis ? "errorInput" : ""}
              htmlFor="M"
            >
              <input
                id="M"
                type="radio"
                name="civilityDevis"
                value="M"
                {...register("civilityDevis")}
                className={`radioCivDevis ${
                  errors.civilityDevis ? "errorInput" : ""
                }`}
              />
              M
            </label>
          </div>
          <div className="civility-opt">
            <label
              className={errors.civilityDevis ? "errorInput" : ""}
              htmlFor="Mme"
            >
              <input
                id="Mme"
                type="radio"
                name="civilityDevis"
                value="Mme"
                {...register("civilityDevis")}
                className={`radioCivDevis ${
                  errors.civilityDevis ? "errorInput" : ""
                }`}
              />
              Mme
            </label>
          </div>
        </div>
        <div className="body-ask-devis">
          <div className="input-devis-container">
            <label htmlFor="person-first-name">Nom</label>
            <input
              type="text"
              id="person-first-name"
              name="personFirstName"
              {...register("personFirstName")}
            />
            {errors.personFirstName && (
              <small className="errorInputConfig">
                {errors.personFirstName?.message}
              </small>
            )}
          </div>
          <div className="input-devis-container">
            <label htmlFor="person-last-name">Prenom</label>
            <input
              type="text"
              id="person-last-name"
              name="personLastName"
              {...register("personLastName")}
            />
            {errors.personLastName && (
              <small className="errorInputConfig">
                {errors.personLastName?.message}
              </small>
            )}
          </div>
          <div className="input-devis-container">
            <label htmlFor="person-email">Email</label>
            <input
              type="email"
              id="person-email"
              name="personEmail"
              {...register("personEmail")}
            />
            {errors.personEmail && (
              <small className="errorInputConfig">
                {errors.personEmail?.message}
              </small>
            )}
          </div>
          <div className="input-devis-container">
            <label htmlFor="person-phone">Telephone</label>
            <input
              type="text"
              id="person-phone"
              name="personPhone"
              {...register("personPhone")}
            />
            {errors.personPhone && (
              <small className="errorInputConfig">
                {errors.personPhone?.message}
              </small>
            )}
          </div>
        </div>
      </div>
      <div className="explain-projet-infos">
        <div className="head-ask-devis">
          <span>Dites nous en plus ?</span>
        </div>
        <div className="body-ask-devis">
          <div className="input-devis-container">
            <label htmlFor="explain-project">Precisions</label>
            <textarea
              name="explainProject"
              id="explain-project"
              resize="none"
              maxLength={1000}
              {...register("explainProject")}
            ></textarea>
            <small>1000 caractères</small>
            {errors.explainProject && (
              <small className="errorInputConfig">
                {errors.explainProject?.message}
              </small>
            )}
          </div>
          <div className="containerSectionFiles">
            <small htmlFor="explain-project">
              Des images ? Telechargez les
            </small>
            <div className="upload">
              <label htmlFor="uploadInput" className="uploadButton">
                <div className="uploadBody">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 99.09 122.88"
                  >
                    <path d="M64.64,13,86.77,36.21H64.64V13ZM42.58,71.67a3.25,3.25,0,0,1-4.92-4.25l9.42-10.91a3.26,3.26,0,0,1,4.59-.33,5.14,5.14,0,0,1,.4.41l9.3,10.28a3.24,3.24,0,0,1-4.81,4.35L52.8,67.07V82.52a3.26,3.26,0,1,1-6.52,0V67.38l-3.7,4.29ZM24.22,85.42a3.26,3.26,0,1,1,6.52,0v7.46H68.36V85.42a3.26,3.26,0,1,1,6.51,0V96.14a3.26,3.26,0,0,1-3.26,3.26H27.48a3.26,3.26,0,0,1-3.26-3.26V85.42ZM99.08,39.19c.15-.57-1.18-2.07-2.68-3.56L63.8,1.36A3.63,3.63,0,0,0,61,0H6.62A6.62,6.62,0,0,0,0,6.62V116.26a6.62,6.62,0,0,0,6.62,6.62H92.46a6.62,6.62,0,0,0,6.62-6.62V39.19Zm-7.4,4.42v71.87H7.4V7.37H57.25V39.9A3.71,3.71,0,0,0,61,43.61Z" />
                  </svg>
                  <p>Glissez le fichier ou cliquez</p>
                </div>
              </label>
              <div className="uploadFooter">
                <input
                  type="file"
                  multiple
                  id="uploadInput"
                  onChange={handleChangeFiles}
                />
              </div>
            </div>
            <div className="uploadedImages">
              {files &&
                Array.from(files).map((file, index) => (
                  <div className="uploacontainer" key={index}>
                    <img
                      src={URL.createObjectURL(file)}
                      alt={`uploaded-item-${index}`}
                    />
                    <div
                      className="containerIconRemove"
                      onClick={() => handleRemoveImage(index)}
                    >
                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 105.7 122.88"
                      >
                        <path d="M30.46,14.57V5.22A5.18,5.18,0,0,1,32,1.55v0A5.19,5.19,0,0,1,35.68,0H70a5.22,5.22,0,0,1,3.67,1.53l0,0a5.22,5.22,0,0,1,1.53,3.67v9.35h27.08a3.36,3.36,0,0,1,3.38,3.37V29.58A3.38,3.38,0,0,1,102.32,33H98.51l-8.3,87.22a3,3,0,0,1-2.95,2.69H18.43a3,3,0,0,1-3-2.95L7.19,33H3.37A3.38,3.38,0,0,1,0,29.58V17.94a3.36,3.36,0,0,1,3.37-3.37Zm36.27,0V8.51H39v6.06ZM49.48,49.25a3.4,3.4,0,0,1,6.8,0v51.81a3.4,3.4,0,1,1-6.8,0V49.25ZM69.59,49a3.4,3.4,0,1,1,6.78.42L73,101.27a3.4,3.4,0,0,1-6.78-.43L69.59,49Zm-40.26.42A3.39,3.39,0,1,1,36.1,49l3.41,51.8a3.39,3.39,0,1,1-6.77.43L29.33,49.46ZM92.51,33.38H13.19l7.94,83.55H84.56l8-83.55Z" />
                      </svg>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="check-box-container">
            <div className="using-cond-container">
              <div className="checkbox-space">
                <input
                  type="checkbox"
                  id="acceptTerms"
                  name="acceptTerms"
                  className={errors.acceptTerms ? "errorInput" : ""}
                  {...register("acceptTerms")}
                />
                <svg
                  className="checked-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 457.57"
                >
                  <path d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z" />
                </svg>
              </div>
              <div
                className={`label-using-cond-container ${
                  errors.acceptTerms ? "errorInput" : ""
                }`}
              >
                <label htmlFor="acceptTerms">
                  En appuyant sur cochant cette case, je reconnais avoir accepté
                  la politique de{" "}
                  <NavLink to="/protection-des-données">
                    protection des données &amp; l'utilisation de cookies
                  </NavLink>
                </label>
              </div>
            </div>
            <div className="newsletter">
              <div className="checkbox-space">
                <input
                  type="checkbox"
                  id="checknews"
                  name="newsletter"
                  {...register("newsletter")}
                />
                <svg
                  className="checked-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 457.57"
                >
                  <path d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z" />
                </svg>
              </div>
              <div className="labelContainer">
                <label htmlFor="checknews">
                  Je souhaite recevoir la Newsletter et être informé(e) de
                  toutes les nouveauté.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="submit-devis-container">
        <button className="black-button" onClick={handleGaData}>Envoyer ma demande de devis</button>
      </div>
      <div className="alertSuccess">{toggleMessage}</div>
    </form>
  );
};

export default AskDevis;
